import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const emailNotificationComputed = {
    ...mapState('emailnotification', {
      email_notifications:(state) => state.email_notifications,
      show_email_notifications:(state) => state.show_email_notifications,
    }),
    ...mapGetters('email_notifications', ['getAllEmailNotification'])
}

// export email account actions
export const emailNotificationMethods = mapActions('emailnotification', ['allEmailNotification', 'addEmailNotification', 'showEmailNotification', 'updateEmailNotification', 'deleteEmailNotification'])
export const emailNotificationMutations = mapMutations('emailnotification', ['SHOW_EMAIL_NOTIFICATION']);

