<script>
/**
 * Dashboard component
 */
 import APPNavbar from '../../../components/app-topbar.vue'
export default {
  components: {
    APPNavbar
  },
  data() {
    return {
        accounts:null
    };
  },
  computed:{
  },
  methods: {
  },
  mounted(){
  }
};
</script>

<template>
   <div class="ctx-email-app-body">
    <div class="ctx-email-app-container">
      <div class="ctx-email-app-side-bar"></div>
      <div class="ctx-email-app-main-content">
        <APPNavbar />
        <h1 class="text-center mt-5">Home Page</h1>
      </div>
    </div>
   </div>
</template>