import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const authUserComputed = {
    ...mapState('authuser', {
        user:(state) => state.user,
    }),
    ...mapGetters('authuser', ['getUser'])
}

// export email account actions
export const authUserMethods = mapActions('authuser', ['getUserData','login', 'register', 'forgetPasword'])
